export const english = {
    fullname: 'Fullname',
    username: 'Username',
    email_address: 'Email Address',
    forgot_email_address: 'Email',
    password: 'Password',
    confirm_password: 'Confirm password',
    date_of_birth: 'Date of birth',
    create_an_account: 'Create an account',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    authentication_failed: 'Oops Authentication is failed, please login again',
    continue_with_facebook: 'Continue with Facebook',
    continue_with_google: 'Continue with Google',
    new: 'new',
    max_16_chars: 'Max 16 characters',
    unconfirmed_email: 'Unconfirmed email.',
    confirm_your_email: 'Please confirm your email first',
    forgot_password: 'Forgot password',
    login_success: 'Log in Success',
    send_new_password_label: 'Enter your email',
    submit: 'Submit',
    confirm_email_first: 'Please confirm your email first',
    reset_your_password: 'Reset your password',
    my_courses: 'My trainings',
    add_a: 'Add a',
    challenge: 'training',
    suggested_courses: 'Suggestions for you',
    buy: 'Buy',
    try_it: 'Try it',
    free: 'Free',
    sponsors: 'Supported by',
    super_challenges: 'Super challenges',
    modules: 'Modules',
    buy_full_course: 'Buy full course',
    payment: 'Payment',
    enter_your_payment_info: 'Please enter your payment information. The transaction is 100% safe',
    investment: 'Investment',
    cpf: 'CPF',
    card_number: 'Card number',
    name_on_card: 'Name on card',
    mm_yyyy: 'MM/YYYY',
    security_code: 'Security code',
    complete_purchase: 'Complete purchase',
    class: 'Training',
    extras: 'Extras',
    exercises: 'Exercises',
    previous_class: 'Previous training',
    next_class: 'Next training',
    title_of_content: 'Title of content',
    view_more: 'View more',
    extra_contents: 'Extra Contents',
    follow: 'Follow',
    comment: 'Comment',
    to_evaluate: 'Evaluate',
    send_exercise: 'Send exercise',
    unfollow: 'Unfollow',
    evaluate_this_exercise: 'Evaluate this exercise',
    submit_review: 'Submit review',
    about_your_teacher: 'About your teacher',
    no_data_available: 'No data available!',
    mission: 'Your turn!',
    tips: 'Tips',
    class_progress: 'Training progress',
    classroom_progress: 'Classroom progress',
    no_image: 'No image',
    loading: 'Loading',
    request_processing: 'Please wait, your request is processing',
    just_now: 'just now',
    course: 'Explore',
    network_of_knowledge: 'Network of Knowledge',
    notifications: 'Notifications',
    logout: 'Logout',
    login_for_nav: 'Login',
    no_challenges_available: 'No challenges available!',
    contact: 'Contact',
    telephone: 'Telephone',
    who_we_are: 'Who we are',
    connect_with_us: 'Connect with us',
    privacy: 'Privacy',
    terms_conditions: 'Terms and conditions',
    start_training_now: 'Click here to start training!',
    share_participation: 'Share a text, audio, video or link',
    share_on_net: 'Share on the net',
    your_discussion_will_be: 'Your discussion will be',
    shared_in_feed: 'shared in Feed. This',
    content_will_be_seen: 'content will be seen',
    by_his_followers: 'by his followers',
    send_mission: 'Send Mission',
    discussions: 'discussions',
    comments: 'Comment(s)',
    report_abuse: 'Report Abuse',
    explain_what_happened: 'Explain what happened here',
    evaluate_the_mission: 'Evaluate the mission',
    unfollow_confirm_message: 'Do you want to unfollow',
    view_profile: 'View profile',
    edit_profile: 'Edit profile',
    enter_current_password: 'Enter your current password',
    enter_new_password: 'Enter your new password',
    current_average: 'Current average',
    following: 'Following',
    followers: 'Followers',
    save_profile: 'Save profile',
    update_password: 'Update password',
    change_password: 'Change password',
    delete: 'Delete',
    confirm_new_password: 'Confirm new password',
    add_valuable_comments: 'Please add your valuable comments',
    page_not_found: 'Page not found!',
    minute: 'minute(s)',
    hour: 'hours(s)',
    day: 'day(s)',
    month: 'month(s)',
    year: 'year(s)',
    language: 'Language',
    per_month: 'month',
    subscription_banner_heading: 'Be a subscriber: The shortest way to become a star',
    subscription_banner_title: 'Ut enim ad minim veniam, quis nostrud',
    subscription_banner_desc: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    not_now: 'Not now',
    sign_it: 'Sign it',
    already_carrier_subscription: 'I have already a mobile carrier subscription',
    continue: 'Continue',
    credit_card: 'Credit card',
    pay_via: 'Pay via',
    ok: 'Ok',
    my_carrier: 'My carrier',
    phone_number: 'Phone number',
    pin: 'PIN',
    confirm: 'Confirm',
    my_progress: 'My progress',
    neymar_train_you: 'Train with Neymar Jr.',
    profile: 'Profile',
    my_challenges: 'My challenges',
    next: 'Next',
    notification_del_confirm_msg: 'Do you want to delete notification',
    delete_site: 'Do you want to delete the site?',
    delete_users: 'Do you want to delete the users?',
    select_users_First: 'please select users for delete operation first !!',
    seconds: 'second(s)',
    copyright: 'Copyright @ 2012 - 2018 earthDECKS',
    messages: {
        invalid_credentials: 'Invalid email or password.',
        email_already_exists: 'Email Already exist.',
        registration_success: 'Your registration has been successful.',
        login_success: 'You have been successfully logged in.',
        logout_success: 'You have been successfully logged out.',
        forgot_password_success: 'We have received your request and an email containing reset password link has been send to your registered email.',
        set_password_success: 'Your password has been set successfully. You may login now.',
        reset_password_invalid_token: 'Invalid reset password token.',
        reset_password_success: 'Your password has been reset successfully. You may now login with the new password.',
        invalid_reset_password_token: 'Reset password token is invalid.',
        invalid_user_or_token: 'Invalid user or token.',
        unauthenticated_user: 'You are not an authenticated user.',
        resource_not_found: 'Resource not found.',
        confirmation_email_sent_success: 'Confirmation email sent successfully.',
        email_sent_success: 'Email has been sent, please check your inbox.',
        default_error: 'Sorry some error occurred, please try again later.',
        course_subscription_success: 'You have been successfully subscribed the course.',
        forbidden: 'You are not authenticated to perform this action.',
        unauthorized_user: 'You are not an authorized user.',
        user_not_found: 'User not found.',
        invalid_course: 'Invalid course.',
        payment_failed: 'Your payment for subscription has been failed. Please contact the administrator.',
        trial_subscription_failed: "You can't subscribe to this course, either there is no free unit(s) or you already have subscribed.",
        comment_success: 'Comment added successfully.',
        participation_success: 'Participation added successfully.',
        rating_success: 'Participation rated successfully.',
        cant_rate_own_participation: 'You can not rate your own participation.',
        cant_rate_participation_again: 'You can not rate the participation again.',
        report_abuse_success: 'Your complaint has been registered successfully.',
        report_abuse_duplicate: 'You have already complaint this participation.',
        follow_success: 'You are successfully following this user.',
        unfollow_success: 'You have successfully unfollowed this user.',
        profile_update_success: 'Your profile has been updated successfully.',
        wrong_old_password: 'You entered incorrect old password.',
        same_old_new_password: 'Your old password and new password can not be same.',
        change_password_success: 'Your password has been successfully updated.',
        change_avatar_success: 'Your profile picture has been updated successfully.',
        otp_unmatch: 'OTP does not match.',
        password_unmatch: 'Password & Confirm password does not match.',
        user_deleted_successfully: 'User has been deleted successfully.',
        user_added_success: 'User added successfully.',
        user_updated_success: 'User updated successfully.',
        confirm_delete_User: 'Do you want to delete User?',
        roles_added_success: 'Roles has been added successfully.',
        roles_update_success: 'Roles has been updated successfully.',
        roles_delete_success: 'Roles has been deleted successfully.',
        confirm_delete_role: 'Do you want to delete the role?',
        role_not_found: 'Roles not found',
        invalid_access: 'Invalid access to the page.',
        notification_delete_success: 'Notification deleted successfully.',
        token_expired: 'Token is Invalid.',
        delete_success: 'The record has been deleted successfully.',
        record_added_success: 'The record has been added successfully.',
        record_updated_success: 'The record has been updated successfully.',
        contact_successfully: 'Thank you, one of our team member will connect you soon.',
        account_updated_successfully: 'Account has been updated successfully.',
        record_not_found: 'Record not found.',
        username_update_success: 'User name has been successfully updated.',
        mobile_number_update_success: 'Mobile number has been successfully updated.',
        email_update_success: 'Email has been successfully updated.',
        address_update_success: 'Address has been successfully updated.',
        profile_image_update_success: 'Profile Image has been successfully updated.',
        classified_list_not_found: 'Classified List not found.',
        mandate_filter: 'Search keyword is required.',
        no_classifieds_found: 'No Classifieds found.',
        change_status: 'Status has been changed successfully.',
        delete_classified_success: 'Classified has been deleted successfully.',
        education_length_warning: 'Educations should not be greater than 5.',
        experience_length_warning: 'Experience should not be greater than 10.',
        dispute_send_sucess: 'Dispute has been sent sucessfully',
        review_sucess: 'Review has been sent sucessfully',
        event_booking_success: 'Event has been booked sucessfully',
        cancel_booking: 'Booking has been cancelled sucessfully',
        no_item_found: 'Items not found.',
        something_went_wrong: 'Something went wrong.',
        classified_mark_sold: 'Classified mark as sold successfully.',
        delete_classified_ad: 'Are you sure you want to delete this AD?',
        paypal_verified_success: 'Your paypal account has been verified succussfully.'

    },
    validation_messages: {
        invalid_email: 'Please enter the valid email address.',
        required: 'This field is required.',
        password: 'Please enter your password!',
        cPassword: 'Please confirm your password!',
        passwordNotMatch: 'Password and confirm password does not match!',
        currentPassword: 'Please enter your current password!',
        newPassword: 'Please enter your new password!',
        imgRequired: 'This field is required.',
        imgSize: 'File size should be less than or equals to 4 mb.',
        imgType: 'Only JPG, JPEG, PNG & PDF files are accepted.',
        newConfirmPassword: 'New password and confirm password does not match!',
        pendingAdValidation: 'Your Ad is pending for approval from Admin',
        expiredAdValidation: 'Your Ad is expired.',
        rejectedAdValidation: 'Your Ad is rejected from Admin.',

    },
    key: {
        SUCCESS: 'SUCCESS',
        top: 'top',
        middle_one: 'middle_one',
        middle_two: 'middle_two',
        middle_three: 'middle_three',
        bottom: 'bottom',
        middle: 'middle',
        private: 'private',
        business: 'business',
        all: 'all',
        All: 'All',
        classified: 'classified',
        retail: 'retail',
        booking: 'booking',
        fitness: 'Fitness',
        events: 'Events',
        caterers: 'Caterers',
        venues: 'Venues',
        entertainment: 'Entertainment',
        photographer: 'Photographer',
        videographer: 'Videographer',
        daily_deals: 'daily_deals',
        beauty: 'Beauty',
        spa: 'Spa',
        restaurant: 'restaurant',
        wellbeing: 'Wellbeing',
        featured_venue: 'featured-venue',
        popular_venue: 'popular-venue',
        dailyDeals: 'daily-deals',
        fitness_promo: 'fitness-promo',
        makeup_promo: 'makeup-promo',
        nutritionists: 'looking-for-nutritionists',
        dietitians: 'looking-for-dietitians',
        special_offer: 'special-offer',
        real_estate: 'real-estate',
        job: 'job',
        car_dealer: 'car-dealer',
        spa_wellness: 'spa-wellness',
        handyman: 'handyman',
        merchant: 'merchant',
        residential: 'Residential Real Estate',
        commercial: 'Commercial Real Estate',
        recent:'recent'
    },
    userType: {
        fitness: 'fitness',
        events: 'events',
        beauty: 'beauty',
        spa: 'spa',
        wellbeing: 'wellbeing',
        handyman: 'handyman'
    }
};
