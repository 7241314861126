// api constant
export const LOGIN = "login";
export const REGISTER = "registerUserAPI";
export const CALL_LOADING = "call_loading";
export const ENABLE_LOADING = "enable_loading";
export const DISABLE_LOADING = "disable_loading";
export const REGISTER_BUSSINESS_USER = "registerBussinessUserAPI";
export const RESET_APP = "RESET_APP";
export const SET_LOGGED_IN_USER = "set_logged_in_user";
export const GET_LOGGED_IN_USER = "get_logged_in_user";
export const LOGOUT = "logout";
export const FETCH_ALL_CATEGORY = "FETCH_ALL_CATEGORY";
export const SET_BOOKING_CATEGORY = "SET_BOOKING_CATEGORY";
export const SET_BOOKING_SUBCATEGORY = "SET_BOOKING_SUBCATEGORY";
export const SET_USER_MENU_LIST = "SET_USER_MENU_LIST";
export const GET_USER_PROFILE = "get_user_profile";
export const SET_SAVED_CATEGORIES = "set_saved_categories";
export const SET_CLASSIFIEDS_CATEGORY_LISTING =
  "set_classifieds_category_listing";
export const SET_SELECTED_CLASSIFIED = "set_selected_classified";
export const ADD_TO_FAVORITE = "add_to_favorite";
export const LOGIN_MODEL = "open_login_model";
export const CLOSE_LOGIN_MODEL = "close_login_model";
export const OPEN_FORGOT_MODEL = "open_forgot_model";
export const CLOSE_FORGOT_MODEL = "close_forgot_model";
export const SEND_OTP = "send_otp";
export const SET_BANNER_PAGE = "set_banner_page";
export const SET_BANNER = "set-banner";
export const SET_RETAIL_LIST = "set_retail_list";
export const SET_MOST_VIEW = "set_most_view";
export const SET_FAVORITE_ITEM_ID = "set_favorite_item_id";
export const SET_FILTER_ATTRIBUTES = "set_filter_attribute";
export const SET_RESUME_DETAILS = "set_resume_details";
export const SET_FITNESS_TYPES = "set_fitness_types";
export const SET_FOOD_TYPES = "set_foof_types";
export const SET_DIETARY_TYPES = "set_dietary_types";

export const STEP1 = "step1";
export const STEP2 = "step2";
export const STEP3 = "step3";
export const STEP4 = "step4";
export const SET_CHILD_CATEGORY = "set_child _category";
export const SET_CLASSIFIED_CATEGORY = "set_classified _category";
export const PAPULAR_SEARCH = "set_papular_search";
export const MOST_PAPULAR_RECORD = "most_papular_record";
export const GET_PLAN = "get_plan";
export const SET_LOCATION = "set_location";
export const FILE_LIST = "FILE_LIST";
export const PREVIEW = "PREVIEW";
export const GET_TRADER_PROFILE = "get_trader_profile";
export const SET_RESTAURANT_DETAIL = "set_restaurant_detail";
export const SET_BROCHURES = "set_brochures";
export const SET_GALLERY = "set_gallery";
export const SET_PORTFOLIO_FOLDER = "set_portfolio_folder";
export const SET_CERTIFICATIONS = "set_certifications";
export const SET_RESTAURANT_CUSTOMER_REVIEWS =
  "set_restaurants_customer_reviews";
export const SET_POPULAR_RESTAURANT = "set_popular_restaurants";
export const SET_POPULAR_VENUE = "set_popular_venue";
export const SET_DEAL_FROM_ADMIN = "set_deal_from-admin";
export const SET_MY_DEALS = "set_my_deals";
export const SET_ELIGIBLE_PROMO = "set_elegible_promo";
export const SET_ELIGIBLE_OFFERS = "set_elegible_offers";
export const SET_MY_OFFERS = "set_my_offers";
export const SET_MY_PROMO = "set_my_promo";
export const SET_ELIGIBLE_PACKAGES = "set_eligible_packages";
export const SET_MY_BEST_PACKAGES = "set_my_best_packages";
export const SET_SPORTS_COUNTRY = "set_sports_country";
export const SET_GENERAL_VENDOR_MY_OFFER = "set_general_venfor_my_offer";
export const SET_CURRENT_ADDRESS = "set_current_location";
export const SET_GST_PERCENTAGE = "set_gst_percentage";
export const SET_MENU_OVERLAY = "set_menu_overlay";
export const SET_ADDRESS_TYPE = "set_address_type";
export const SET_ADDRESS = "set address";
export const SET_RETAIL_SUB_CATEGORIES = "set-retail-sub-category";
export const SET_FLIGHT_RECORD = "set_flight_records";
export const SET_MULTICITY_VALUE = "set-multicity-values";
export const SET_FLIGHT_SERACH_PARAMS = "set_flight_search_data";
export const SET_TOKEN = "set-flight-token";
export const SET_CAR_SEARCH_DATA = "set-car-search-data";
export const SET_CAR_REQ_DATA = "set-car-request-data";
export const SET_ORIGIN = "set_origin";
export const SET_SELECTED_CAR_DATA = "set_selected_car_data";
export const SET_HOTEL_SEARCH_DATA = "set-hotel-search-data";
export const SET_HOTEL_REQ_DATA = "set-hotel-search-req-data";
export const GET_SELETED_HOTEL = "get-selected-hotel-details";
export const GET_SERVICE_AREA_OPTION = "get-service-area-option";
export const ADD_CHECKOUT_DATA = "add-checkout-data";
export const REMOVE_CHECKOUT_DATA = "remove-checkout-data";
